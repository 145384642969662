import { createStore } from 'vuex'

export default createStore({
  state: {
    baseurl: 'https://api.lanzhoulan.cn/',
    htmlurl: 'https://web.lanzhoulan.cn/',
    project: 'ebookmark',
    noavatar: 'https://mealfile.s5yun.com/meal_ding20392bf8b4b79d1135c2f4657eb6378f/20211014/1634201457123556109.png',
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
