import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {
  isMobile
} from "@/utils/checkMobile"

if (isMobile()) {
  const setHtmlFontSize = () => {
    const htmlDom = document.getElementsByTagName('html')[0];
    let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
    if (htmlWidth >= 750) {
      htmlWidth = 750;
    }
    if (htmlWidth <= 220) {
      htmlWidth = 220;
    }
    htmlDom.style.fontSize = `${htmlWidth / 7.5}px`;
  };
  window.onresize = setHtmlFontSize;
  setHtmlFontSize();
}

//引入element
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

//引入vant
import Vant from 'vant';
import 'vant/lib/index.css'

//引入图标
import '@/assets/styles/icon.css';

//路由标题
router.beforeEach((to:any, form:any, next:any) => { 
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

const app = createApp(App)
app.use(store)
app.use(router)
app.use(Vant)
app.use(ElementPlus, {
  locale: zhCn
})
app.mount('#app')