import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    meta: {title: "E书签"},
    component: () => import('../views/userLogin.vue')
  },
  // {
  //   path: '/register',
  //   meta: {title: "E书签新用户注册"},
  //   component: () => import('../views/register.vue')
  // },
  // {
  //   path: '/repassword',
  //   meta: {title: "E书签重置密码"},
  //   component: () => import('../views/repassword.vue')
  // },
  // {
  //   path: '/mypassword',
  //   meta: {title: "E书签重置密码"},
  //   component: () => import('../views/mypassword.vue')
  // },
  //pc
  {
    path: '/homeindex',
    meta: {title: "E书签"},
    component: () => import('../views/pc/homeIndex.vue'),
    children: [{
      path: '/index_PC',
      meta: {title: "E书签"},
      component: () => import('../views/pc/homePC.vue'),
    },
    {
      path: '/images',
      meta: {title: "E书签"},
      component: () => import('../views/pc/imagesIndex.vue'),
    },{
      path: '/files',
      meta: {title: "E书签"},
      component: () => import('../views/pc/filesIndex.vue'),
    },{
      path: '/notes',
      meta: {title: "E书签"},
      component: () => import('../views/pc/notesIndex.vue'),
    },{
      path: '/ticket',
      meta: {title: "E书签"},
      component: () => import('../views/pc/ticketIndex.vue'),
    }
    ]
  },
  // //移动端
  // {
  //   path: '/phoneindex',
  //   meta: {title: "E书签"},
  //   component: () => import('../views/phone/index.vue'),
  //   children: [{
  //     path: '/index_MO',
  //     meta: {title: "E书签"},
  //     component: () => import('../views/phone/home.vue'),
  //   },
  //   {
  //     path: '/mbook',
  //     meta: {title: "书签"},
  //     component: () => import('../views/phone/mbook.vue'),
  //   },{
  //     path: '/mimages',
  //     meta: {title: "图片"},
  //     component: () => import('../views/phone/mimages.vue'),
  //   },{
  //     path: '/mfile',
  //     meta: {title: "文档"},
  //     component: () => import('../views/phone/mfile.vue'),
  //   },{
  //     path: '/mticket',
  //     meta: {title: "票据"},
  //     component: () => import('../views/phone/mticket.vue'),
  //   },{
  //     path: '/mnote',
  //     meta: {title: "记事本"},
  //     component: () => import('../views/phone/mnote.vue'),
  //   }
  //   ]
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
